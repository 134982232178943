<template>
  <div>
    <b-overlay :show="isLoadingIntentCategories">
      <b-row>
        <b-col>
          <h1>Intents</h1>
        </b-col>
        <b-col cols="4">
          <b-button
            class="float-right"
            size="sm"
            variant="outline-primary"
            @click="goToIntentMapping"
          >
            <b-icon-bezier />
            Show Mappings
          </b-button>
          <b-button
            class="float-right mr-2"
            size="sm"
            variant="outline-primary"
            @click="exportIntents"
          >
            <b-icon-file-earmark-excel />
            Export
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="3">
          <b-row class="mb-3">
            <b-col>
              <bot-selector
                @botChanged="
                  (botId) => {
                    this.botId = botId;
                  }
                "
              />
            </b-col>
          </b-row>
          <b-row class="intent-category-panel">
            <b-col>
              <intent-category-list
                ref="intentCategories"
                :bot-id="botId"
                @selected="
                  (ic) => {
                    this.selectedIntentCategory = ic;
                  }
                "
                @listLoaded="
                  (list) => {
                    this.intentCategories = list;
                    this.isLoadingIntentCategories = false;
                  }
                "
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="9">
          <b-overlay v-if="!isLoadingIntentCategories" :show="isLoadingIntents">
            <b-row class="mb-3">
              <b-col cols="10">
                <b-input
                  type="search"
                  v-model="searchIntents"
                  size="sm"
                  placeholder='search e.g. intent name keyword "Memb" or intent ID "1234"'
                  debounce="300"
                ></b-input>
              </b-col>
              <b-col>
                <b-dropdown
                  text="Sort"
                  block
                  split
                  split-variant="outline-primary"
                  variant="outline-primary"
                  size="sm"
                  class="float-right"
                  @click="
                    orderDirection = orderDirection === 'desc' ? 'asc' : 'desc'
                  "
                >
                  <template #button-content>
                    <b-icon
                      :icon="
                        orderDirection === 'desc'
                          ? 'sort-down'
                          : 'sort-down-alt'
                      "
                    />
                  </template>
                  <b-dropdown-item
                    :active="orderBy === 'alphabetical'"
                    @click="orderBy = 'alphabetical'"
                  >
                    Alphabetical
                  </b-dropdown-item>
                  <b-dropdown-item
                    :active="orderBy === 'date'"
                    @click="orderBy = 'date'"
                  >
                    Date
                  </b-dropdown-item>
                  <b-dropdown-item
                    :active="orderBy === 'score'"
                    @click="orderBy = 'score'"
                  >
                    Score (min)
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <span class="intent-category-title">
                  {{
                    selectedIntentCategory.parentId
                      ? selectedIntentCategory.intentCategoryName
                      : 'All intents'
                  }}
                  <small class="text-muted"
                    >({{ filteredIntents.length }})</small
                  >
                </span>
                <b-button
                  class="d-inline-block ml-3"
                  variant="primary"
                  size="sm"
                  @click="add"
                >
                  Create
                  <b-icon-plus />
                </b-button>
                <intent-recent-activity-log
                  class="float-right"
                  :bot-id="botId"
                  @selected="(id) => showIntentById(id)"
                />
              </b-col>
            </b-row>
            <b-row class="intent-panel">
              <b-col>
                <b-list-group>
                  <b-list-group-item
                    v-for="intent in filteredIntents"
                    :key="intent.intentId"
                  >
                    <div>
                      <span
                        class="intent-name d-inline-block text-truncate"
                        :title="intent.intentName"
                        @click="edit(intent)"
                        >{{ intent.intentName }}</span
                      >
                      <b-badge
                        v-if="intent.minimumSentenceScore"
                        variant="info"
                        class="mt-1 ml-3 align-top"
                        title="Minimum Sentence Score"
                      >
                        {{ displayQuestionScore(intent.minimumSentenceScore) }}
                      </b-badge>
                      <b-button-close
                        class="remove-button float-right mt-2"
                        size="sm"
                        title="remove"
                        @click="remove(intent)"
                      />
                    </div>
                    <div class="d-inline" v-if="intent.editedAt">
                      <small class="text-muted d-block text-truncate">
                        <span v-b-tooltip.hover="editedTooltip(intent)"
                          >Last edited:
                          {{ intent.editedAt | datetimelongutz }}</span
                        >

                        <intent-activity-log
                          v-if="
                            isAfter(
                              intent.editedAt,
                              intentActivityLogStartDatetime
                            )
                          "
                          class="ml-3"
                          :intent-id="intent.intentId"
                        />
                      </small>
                    </div>
                    <div v-else-if="intent.createdAt">
                      <small
                        v-b-tooltip.hover="editedTooltip(intent)"
                        class="text-muted d-block text-truncate"
                      >
                        Created: {{ intent.createdAt | datetimelongutz }}
                      </small>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal
      v-model="formModal"
      size="lg"
      button-size="sm"
      scrollable
      :title="formModalTitle"
      ok-title="Submit"
      @ok="submit"
    >
      <intent-form
        ref="intent-form"
        :selected-bot-id="botId"
        :selected-intent="formItem"
        :selected-intent-category-id="selectedIntentCategory.intentCategoryId"
        :intent-categories="intentCategories"
        :intents="intents"
        :webhooks="webhooks"
        @edited="edited"
        @created="created"
      ></intent-form>
    </b-modal>

    <b-modal
      v-model="intentMappingModal"
      title="Intent Mapping"
      size="xl"
      scrollable
      hide-footer
    >
      <intent-mapping :trees="trees" @intentSelected="edit" />
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import HttpHandler from '@/mixins/HttpHandler';
import IntentCategoryList from '@/components/Intents/IntentCategoryList';
import IntentForm from '@/components/Intents/IntentForm';
import BotSelector from '@/components/UI/BotSelector';
import IntentMapping from '@/components/Intents/IntentMapping';
import IntentActivityLog from '@/components/Intents/IntentActivityLog';
import IntentRecentActivityLog from '@/components/Intents/IntentRecentActivityLog';
import AppConfigs from '../../../appcode/AppConfigs';
import dayjs from 'dayjs';

export default {
  name: 'Intents',
  mixins: [HttpHandler],
  components: {
    IntentCategoryList,
    IntentForm,
    BotSelector,
    IntentMapping,
    IntentActivityLog,
    IntentRecentActivityLog,
  },
  data() {
    return {
      botId: null,
      bots: [],
      webhooks: [],
      trees: _defaultTrees(),
      intents: [],
      intentCategories: [],
      selectedIntentCategory: {
        intentCategoryId: null,
        intentCategoryName: '',
        parentId: null,
      },
      searchIntents: '',
      formModal: false,
      formModalTitle: 'Create',
      formItem: {},
      isLoadingIntentCategories: false,
      isLoadingIntents: false,
      intentMappingModal: false,

      orderDirection: 'desc', //desc/asc
      orderBy: 'date', //alphabetical/date/score
    };
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.botId = botId;

    this.isLoadingIntentCategories = true;
    this.getWebhooks();
    this.getTrees();
    this.getIntents();
  },
  watch: {
    botId() {
      this.isLoadingIntentCategories = true;
      this.getWebhooks();
      this.getTrees();
      this.getIntents();
    },
  },
  computed: {
    filteredIntents() {
      let intents = [];
      //filter by intent category
      if (this.selectedIntentCategory.parentId) {
        intents = _.filter(this.intents, {
          intentCategoryId: this.selectedIntentCategory.intentCategoryId,
        });
      } else {
        intents = this.intents;
      }
      //filter by search term
      if (this.searchIntents) {
        intents = _.filter(intents, (a) => {
          return (
            a.intentName
              .toLowerCase()
              .includes(this.searchIntents.toLowerCase()) ||
            a.intentId.toString() === this.searchIntents
          );
        });
      }
      let orderDirection = this.orderDirection;
      let orderBy = this.orderBy;
      //order by modified or created date
      intents = _.orderBy(
        intents,
        (o) => {
          if (orderBy === 'alphabetical') {
            return o.intentName.toLowerCase();
          }
          if (orderBy === 'score') {
            return parseFloat(o.minimumSentenceScore) || 0;
          }

          return o.editedAt || o.createdAt;
        },
        [orderDirection]
      );
      return intents;
    },
    intentActivityLogStartDatetime() {
      const startDatetime = _.find(this.$store.state['appConfigs'], {
        id: AppConfigs.INTENT_ACTIVITY_LOG_START_DATETIME,
      });
      if (startDatetime) {
        let obj = JSON.parse(startDatetime.config);
        return obj.datetime;
      }
      return '';
    },
  },
  methods: {
    editedTooltip(item) {
      let title = '';
      if (item.editedAt) {
        title =
          'Edited by: ' +
          item.editedBy.split('____')[0] +
          '<br/> Edited at: ' +
          this.$options.filters.datetimelong(item.editedAt);
      } else if (item.createdAt) {
        title =
          'Created by: ' +
          item.createdBy.split('____')[0] +
          '<br/> Created at: ' +
          this.$options.filters.datetimelong(item.createdAt);
      }
      return {
        html: true,
        title: title,
        variant: 'dark',
        placement: 'left',
        boundary: 'viewport',
      };
    },
    getIntents() {
      this.isLoadingIntents = true;
      return this.httpclient
        .get('/api/intents', { params: { botId: this.botId } })
        .then((resp) => {
          this.intents = resp.data;
        })
        .then(() => {
          this.isLoadingIntents = false;
        });
    },
    getWebhooks() {
      return this.httpclient
        .get('/api/webhooks', { params: { botId: this.botId } })
        .then((resp) => {
          this.webhooks = resp.data;
        });
    },
    getTrees() {
      this.trees = null;

      this.httpclient
        .get('/api/bots/' + this.botId + '/intent-mappings')
        .then((resp) => {
          this.trees = resp.data;
        });
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm('Proceed to remove the Intent?', {
          centered: true,
          bodyTextVariant: 'danger',
          okTitle: 'Remove',
          okVariant: 'danger',
        })
        .then((confirm) => {
          if (confirm) {
            this.httpclient.delete('api/intents/' + item.intentId).then(() => {
              this.getIntents();
              const selectedIntentCategory = _.cloneDeep(
                this.selectedIntentCategory
              );
              this.$refs.intentCategories.getList().then(() => {
                this.selectedIntentCategory = selectedIntentCategory;
              });
              this.$bvModal.msgBoxOk(
                'The Intent, [' +
                  item.intentName +
                  '] had been removed successfully.',
                {
                  okVariant: 'success',
                  centered: true,
                }
              );
            });
          }
        });
    },
    add() {
      this.formModal = true;
      this.formModalTitle = 'Create';
      this.formItem = { intentId: null };
    },
    edit(intent) {
      this.formModal = true;
      this.formModalTitle = 'Edit';
      this.formItem = intent;
    },
    submit(e) {
      e.preventDefault();
      this.$refs['intent-form'].submit();
    },
    created() {
      this.formModal = false;
      this.getIntents();
      const selectedIntentCategory = _.cloneDeep(this.selectedIntentCategory);
      this.$refs.intentCategories.getList().then(() => {
        this.selectedIntentCategory = selectedIntentCategory;
      });
    },
    edited() {
      this.formModal = false;
      this.getIntents();
    },
    goToIntentMapping() {
      this.intentMappingModal = true;
    },
    showIntentById(id) {
      let root = _.find(this.intentCategories, {
        html: '/',
      });
      this.selectedIntentCategory = root.value;
      this.searchIntents = id.toString();
    },
    isAfter(date1, date2) {
      return dayjs(date1).isAfter(date2);
    },
    displayQuestionScore(score) {
      const floatScore = parseFloat(score);
      return isNaN(floatScore) ? '' : floatScore.toFixed(1);
    },
    exportIntents() {
      this.isBusy = true;
      this._downloadFile('api/intents/export', this.botId, function () {
        this.isBusy = false;
      });
    },
  },
};

function _defaultTrees() {
  return [
    {
      label: '1',
      children: [
        {
          label: '2',
          children: [{ label: '3', children: [{ label: '4' }] }],
        },
      ],
    },
  ];
}
</script>

<style scoped>
.intent-category-panel {
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 1em;
}

@media (min-width: 992px) {
  .intent-category-panel {
    height: calc(100vh - 236px);
    margin-bottom: 0;
  }
}

.intent-panel {
  overflow: auto;
  white-space: nowrap;
  height: calc(100vh - 280px);
}

.intent-name {
  cursor: pointer;
  color: #007bff;
}

.intent-name:hover {
  color: #0a0a0a;
}

.remove-button {
  cursor: pointer;
}
</style>
